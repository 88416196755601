@import "src/themes/mixins";

@mixin baseStyle {
    margin: 0;
    padding: 0;
    border: none;
    z-index: 1;
}

.heading1 {
    @include heading1;
    @include baseStyle;
}

.heading2-bold {
    @include heading2-bold;
    @include baseStyle;
}

.heading2-light {
    @include heading2-light;
    @include baseStyle;
}

.variant-heading {
    @include variant-heading;
    @include baseStyle;
}

.heading3-light {
    @include heading3-light;
    @include baseStyle;
}

.heading3-bold {
    @include heading3-bold;
    @include baseStyle;
}

.heading4-bold {
    @include heading4-bold;
    @include baseStyle;
}

.heading4-light {
    @include heading4-light;
    @include baseStyle;
}

.heading5 {
    @include heading5;
    @include baseStyle;
}

.heading5-light {
    @include heading5-light;
    @include baseStyle;
}

.heading6 {
    @include heading6;
    @include baseStyle;
}

.introtext {
    @include introtext;
    @include baseStyle;
}

.toplineOrLabel {
    @include toplineOrLabel;
    @include baseStyle;
    &.inverted {
        color: map-get($colors, "greys", "middleLight");
    }
}

.body {
    @include body;
    @include baseStyle;
    a {
        text-decoration: underline;
        font-weight: bold;
        color: map-get($colors, "greys", "dark");
        &:hover {
            color: map-get($colors, "primary", "middleDark");
        }
    }
}

.body-small {
    @include body;
    @include baseStyle;
    font-size: 16px;
}

.caption {
    @include caption;
    @include baseStyle;
}

.input {
    @include input;
    @include baseStyle;
}

.input {
    @include input;
    @include baseStyle;
}

.button {
    @include button;
    @include baseStyle;
}

.quote {
    @include quote;
    @include baseStyle;
}

.link-text {
    @include body;
    @include link;
    @include baseStyle;
}

.link-small {
    @include button;
    @include link-small;
}

.mobile-nav-link {
    @include mobile-nav-link;
    @include baseStyle;
}

.table-title {
    @include table-title;
    @include baseStyle;
}

.table-value {
    @include table-value;
    @include baseStyle;
}

.keyword-title {
    @include keyword-title;
    @include baseStyle;
}

.toast {
    @include toast;
    @include baseStyle;
}

.paragraph {
    @include body;
    a {
        text-decoration: underline;
        font-weight: bold;
        color: map-get($colors, "greys", "dark");
        &:hover {
            color: map-get($colors, "primary", "middleDark");
        }
    }
}

.faq-heading {
    @include heading5;
    @include baseStyle;
    font-size: 16px;
}

.footer-paragraph {
    @include footer-paragraph;
    @include baseStyle;
}

.footer-link {
    @include footer-link;
    @include baseStyle;
}

.quote-text {
    @include quote-text;
    @include baseStyle;
}

.countdown {
    @include countdown;
    @include baseStyle;
}

.inverted {
    color: map-get($colors, "greys", "light");

    a {
        text-decoration: underline;
        font-weight: bold;
        color: map-get($colors, "greys", "light");
        &:hover {
            color: map-get($colors, "primary", "middleDark");
        }
    }
    ul {
        li {
            list-style: none;
            background: url('../../../assets/li-bullet-invertiert.svg') no-repeat left;
            padding: 4px 0px 3px 24px;
        }
    }
}
