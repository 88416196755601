@import 'src/themes/variables.module';

.top-btn-wrapper{
    position: fixed;
    bottom: 3px;
    right: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    z-index: map-get($zIndices, "toasts");
    margin: 0 !important;
    border: 0;
    padding-right: 7px !important;
    display: inline-block;
    place-content: center;
    overflow: hidden;
}
