.grecaptcha-badge {
    display: none;
}

.react-toast-notifications__container {
    width: min(100%, 800px);
    padding: 0 !important;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
}

#__next {
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: unset;
    height: unset;
    min-height: unset;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-justify {
    text-align: justify;
}

ol {
    list-style-position: inside;
}
p {
    ul {
        list-style-position: inside;

        li {
            list-style: none;
            background: url(../assets/li-bullet.svg) no-repeat left;
            padding: 4px 0 3px 24px;
        }
    }
}