@import 'src/themes/variables.module';

@keyframes slideInFromLeft {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.toast {
    min-width: 300px;
    width: calc(100% - 20px);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: map-get($colors, greys, light);
    animation: slideInFromLeft 1s;

    .left-toast {
        display: flex;
        flex-direction: row;
        align-items: center;

        .toast-text {
            margin-left: 16px;
            padding: 8px;
        }

        .info-card {
            display: flex;
            flex-direction: row;

            .info-card-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                width: 40px;
            }

            .info-card-arrow {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 29px 0 29px 20px;
            }
        }
    }

    .close-icon {
        margin-right: #{map-get($spacings, 'smallLarge')};
        cursor: pointer;
        margin-top: #{map-get($spacings, 'smallLarge')};
        align-self: flex-start;
    }
}

.error {
    border: 1px solid map-get($colors, signalColors, error);

    .info-card-icon {
        background-color: map-get($colors, signalColors, error);
    }

    .info-card-arrow {
        border-color: transparent transparent transparent
            map-get($colors, signalColors, error);
    }

    .close-icon,
    .toast-text {
        color: map-get($colors, signalColors, error);
    }
}

.info {
    border: 1px solid map-get($colors, signalColors, warning);

    .info-card-icon {
        background-color: map-get($colors, signalColors, warning);
    }

    .info-card-arrow {
        border-color: transparent transparent transparent
            map-get($colors, signalColors, warning);
    }

    .close-icon,
    .toast-text {
        color: map-get($colors, signalColors, warning);
    }
}

.success {
    border: 1px solid map-get($colors, signalColors, success);

    .info-card-icon {
        background-color: map-get($colors, signalColors, success);
    }

    .info-card-arrow {
        border-color: transparent transparent transparent
            map-get($colors, signalColors, success);
    }

    .close-icon,
    .toast-text {
        color: map-get($colors, signalColors, success);
    }
}
