html {
    min-height: 100vh;
    font-family: hero-new, sans-serif;
}

#__next {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
